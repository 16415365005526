import {
  REFRESH_ANNOUNCEMENT,
  GET_ANNOUNCEMENT,
  SET_ANNOUNCEMENT,
  SET_LOGGED,
  SET_UPCOMING,
  SET_PREVIOUS,
} from "./types";
const initialState = {
  initialize: true,
  refresh: false,
  isRequesting: false,
  loadMore: true,
  upComing: [],
  previous: [],
  logged:{},
  limit: 10,
  page: 1,
  pages: [],
  initializeOption: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFRESH_ANNOUNCEMENT:
      return {
        ...state,
        limit: 10,
        page: 1,
        initialize: true,
        refresh: true,
        isRequesting: false,
        loadMore: true,
        upComing: [],
        previous: [],
        logged:{},
        initializeOption: true,
      };

    case GET_ANNOUNCEMENT:
      return {
        ...state,
        refresh: false,
        isRequesting: true,
      };

    case SET_ANNOUNCEMENT:  

      return {
        ...state,
        isRequesting: false,
        initialize: false,
        // page: payload.page,
        // loadMore: payload.loadMore, 
        upComing: payload.upComing,
        previous: payload.previous,
        logged: payload.logged,
        // pages: tempPages.length > 0 ? tempPages : state.pages
      };
    
    case SET_LOGGED:  
    
        return {
        ...state,
        isRequesting: false,
        initialize: false,
        logged: payload,
        };

    case SET_UPCOMING:  
    
        return {
        ...state,
        isRequesting: false,
        initialize: false,
        upComing: payload,
        };

    case SET_PREVIOUS:
        return {
        ...state,
        isRequesting: false,
        initialize: false,
        previous: payload,
    };
    default:
      return state;
  }
};

export default reducer;
