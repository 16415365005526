import React from "react";
import { Link } from "react-router-dom";
import { Context } from "context/context";
import {
  Dashboard,
  Transaction,
  Users,
  Categories,
  Roles,
  Teams,
  Close,
  Settings,
  MegaPhone
} from "assets/svg/sidebar/icons"; 
import Logo from "assets/images/logo-white.png";
import { PiWarningOctagon } from "react-icons/pi";

const linkClass = `sidebar-link items-center group w-full hover:text-white text-darkGray flex gap-4 cursor-pointer`;
const linkClassActive = `sidebar-link items-center group w-full text-white  flex gap-4 is-active bg-lightSecondary`;
const iconWrapperClass = "sidebar-icon-bg flex-middle group-hover:bg-secondary";
const iconWrapperClassActive = "sidebar-icon-bg flex-middle bg-lightSecondary";
const iconClass = "group-hover:fill-primary";
const iconClassActive = "fill-primary";

export default function SideBarContent({ routeName, user }) {
  const { setMobileSidebar } = React.useContext(Context);

  return (
    <div className="w-full">
      <div
        className="logo-bar block justify-between text-center flex items-center w-full bg-primary"
        style={{ height: "79px" }}
      >
        <div className="logo-sidebar-container">
          <Link to="/">
            <img
              src={Logo}
              alt="milestone-page-logo"
              className="logo-sidebar-header"
            />
          </Link>
        </div>

        <button
          onClick={() => setMobileSidebar(false)}
          className={"lg:hidden "}
        >
          <Close className="md:h-8 md:w-8" />
        </button>
      </div>
      <div className="list-of-links">
        <Link
          className={routeName === "dashboard" ? linkClassActive : linkClass}
          to={"/"}
        >
          <div
            className={
              routeName === "dashboard"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <Dashboard
              className={
                routeName === "dashboard" ? iconClassActive : iconClass
              }
            />
          </div>
          Dashboard
        </Link>
        <Link
          className={routeName === "transaction" ? linkClassActive : linkClass}
          to={"/transaction"}
        >
          <div
            className={
              routeName === "transaction"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <Transaction className={iconClass} />
          </div>
          Transactions
        </Link> 
        <Link
          className={routeName === "announcement" ? linkClassActive : linkClass}
          to={"/announcement"}
        >
          <div
            className={
              routeName === "announcement"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <MegaPhone className={iconClass} />
          </div>
          Announcement
        </Link> 
        <Link
          className={routeName === "users" ? linkClassActive : linkClass}
          to={"/users"}
        >
          <div
            className={
              routeName === "users" ? iconWrapperClassActive : iconWrapperClass
            }
          >
            <Users className={iconClass} />
          </div>
          Users
        </Link>

        <Link
          className={routeName === "group" || routeName === "group/:id"  ? linkClassActive : linkClass}
          to={"/group"}
        >
          <div
            className={
              routeName === "group" ? iconWrapperClassActive : iconWrapperClass
            }
          >
            <Users className={iconClass} />
          </div>
          Group Management
        </Link>

        <Link
          className={routeName === "categories" ? linkClassActive : linkClass}
          to={"/categories"}
        >
          <div
            className={
              routeName === "categories"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <Categories className={iconClass} />
          </div>
          Categories
        </Link>

        <Link
          className={routeName === "roles" ? linkClassActive : linkClass}
          to={"/roles"}
        >
          <div
            className={
              routeName === "roles"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <Roles className={iconClass} />
          </div>
          Roles
        </Link>

        <Link
          className={routeName === "teams" ? linkClassActive : linkClass}
          to={"/teams"}
        >
          <div
            className={
              routeName === "teams" ? iconWrapperClassActive : iconWrapperClass
            }
          >
            <Teams className={iconClass} />
          </div>
          Team Members
        </Link>

        <Link
          className={routeName === "reports" ? linkClassActive : linkClass}
          to={"/reports"}
        >
          <div
            className={
              routeName === "reports" ? iconWrapperClassActive : iconWrapperClass
            }
          >
            <PiWarningOctagon className={`${iconClass} text-[2rem]`} />
          </div>
          Reports
        </Link>

        {user && (
          <>
            <div className="border-t border-lightGray my-5 mx-10" />
            {/* divider */}
            <Link
              className={routeName === "settings" ? linkClassActive : linkClass}
              to={"/settings"}
            >
              <div
                className={
                  routeName === "settings"
                    ? iconWrapperClassActive
                    : iconWrapperClass
                }
              >
                <Settings className={iconClass} />
              </div>
              Settings
            </Link>
          </>
        )}
      </div>
    </div>
  );
}
