import Maintenance from "pages/maintenance/maintenance";
import Login from "pages/login/login";
import Dashboard from "pages/dashboard/dashboard";
import NotFoundPage from "pages/not-found-page/not-found-page";
import ForgotPassword from "pages/forgot-password/forgot-password";
import PasswordReset from "pages/password-reset/password-reset";
import Transaction from "pages/transaction/transaction";
import UserManagement from "pages/users/users";
import Categories from "pages/categories/categories";
import Roles from "pages/roles/roles";
import TeamMembers from "pages/teams/teams";
import Settings from "pages/settings/settings";
import Reports from "pages/reports/reports";
import Group from "pages/group/group";
import GroupInfo from "pages/group/group-info";
import Announcement from "pages/announcement/announcement";

const routes = [
  {
    path: "/",
    authRequired: true,
    component: () => <Dashboard />,
  },
  {
    path: "/transaction",
    authRequired: true,
    component: () => <Transaction />,
  },
  {
    path: "/announcement",
    authRequired: true,
    component: () => <Announcement />,
  },
  {
    path: "/users",
    authRequired: true,
    component: () => <UserManagement />,
  },
  {
    path: "/group",
    authRequired: true,
    component: () => <Group />,
  },
  {
    path: "/group/:id",
    authRequired: true,
    component: () => <GroupInfo />,
  },
  {
    path: "/categories",
    authRequired: true,
    component: () => <Categories />,
  },
  {
    path: "/roles",
    authRequired: true,
    component: () => <Roles />,
  },
  {
    path: "/teams",
    authRequired: true,
    component: () => <TeamMembers />,
  },
  {
    path: "/reports",
    authRequired: true,
    component: () => <Reports />,
  },
  {
    path: "/settings",
    authRequired: true,
    component: () => <Settings />,
  },
  {
    path: "/forgot-password",
    authRequired: false,
    component: () => <ForgotPassword />,
  },
  {
    path: "/password-reset",
    authRequired: false,
    component: () => <PasswordReset />,
  },
  {
    path: "/maintenance",
    authRequired: false,
    component: () => <Maintenance />,
  },
  {
    path: "/login",
    authRequired: false,
    component: () => <Login />,
  },
  {
    path: "*",
    exact: true,
    component: () => <NotFoundPage />,
  },
];

export default routes;
