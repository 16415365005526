import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "layouts/layout";
import { useDialogHook } from "utils/customhooks";
import { urlSerializer } from "utils/utils";
import Input from "components/input/input";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";
import Radio from "components/radio/radio";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import AnnouncementDeleteModal from "./delete";

import {
  GET_ANNOUNCEMENT,
  SET_ANNOUNCEMENT,
  SET_LOGGED,
  SET_UPCOMING,
  SET_PREVIOUS
} from "redux/modules/announcement/types";

export default function Announcement() {
  const { http } = global.services;
  const {
    upComing = [],
    previous=[],
    logged={},
    page,
    limit,
    initialize,
    refresh,
    loadMore,
    isRequesting,
    pages = [],
  } = useSelector((state) => state.announcement);

  const deleteAnn = useDialogHook(AnnouncementDeleteModal);
  const dispatch = useDispatch();
  const borderColor = {borderColor:'#e5e5e5'}
  const [welcomeAnn, setWelcomeAnn] = useState(logged || {id:null,url:null,message:"logged"}); 
  const [shedAnn, setShedAnn] = useState({id:null,url:null,message:"schedule", date:null, time:null, schedule:"For later"}); 
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const getAnnouncements = async () => {
    if (isRequesting || !loadMore) return;

    dispatch({ type: GET_ANNOUNCEMENT, payload: {upComing:[], previous:[],logged:{}} });

    try {
      const url = urlSerializer("announcements");
      const { data } = await http.get(url); 
      const { logged,upComing,previous } = data;
      setWelcomeAnn({...welcomeAnn,...logged}); 

      dispatch({
        type: SET_ANNOUNCEMENT,
        payload: {
          logged: logged,
          upComing: upComing,
          previous: previous
        },
      });
    } catch (err) {
      console.log("error@getTransactions", err);
    }
  };

  const saveHandle = async(type)=>{
    setLoading(true);
    let errors ={};
    try{
        const url = urlSerializer("announcements");
        const payload = type === 'logged' ? welcomeAnn : shedAnn;
        const { data } = await http.post(url,{...payload, type:type});
        if(type === 'schedule'){ 
          if(data.isUpcoming){
            let payload = [...upComing, data];
            dispatch({
              type: SET_UPCOMING,
              payload,
            })
          }else{
            let payload = [...previous, data]; 
            dispatch({
              type: SET_PREVIOUS,
              payload,
            })
          }
        }
        toast.success("Successfully");
    }catch(e){
      const { data, status } = e;
      console.error(e);
      toast.error(`It appears that something went wrong`); 
      if (status === 422) {
        errors = data.errors;
      }
    }finally{
        setLoading(false)
    }
    setErrors({...errors, type:type} || {});
    
  }

  const onHandleChange = (e)=>{
    const value = e.target.value; 
    setWelcomeAnn({...welcomeAnn, url:value})
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target; 
    if(value === 'For now' || value === 'For later'){
      const now = new Date();
      const date = now.getFullYear() +
      '-' +
      String(now.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(now.getDate() +  parseInt(`${value === 'For later' ? 3 : 0}`)).padStart(2, '0');
      const time = now.toTimeString().split(' ')[0].substring(0, 5);
      setShedAnn((prev) => ({
        ...prev,
        [name]: value, 
        time: time,
        date:date
      }));
      return;
    }
    setShedAnn((prev) => ({
      ...prev,
      [name]: value, 
    }));
  };

  useEffect(() => {
    if (initialize) getAnnouncements();
  }, [refresh, initialize]);

  const showDelete = (item,type)=>{
    deleteAnn({...item},callback=>{
      if(callback?.success){
        if(type === 'upComing'){
          let payload = [...upComing];
          payload = payload.filter((i)=>i.id !== callback?.id)
          dispatch({
            type: SET_UPCOMING,
            payload: payload,
          })
        }else{
          let payload = [...previous];
          payload = payload.filter((i)=>i.id !== callback?.id)
          dispatch({
            type: SET_PREVIOUS,
            payload: payload,
          })
        } 
        toast.success("Successfully deleted");
      }
    })
  }

  return (
    <Layout title="Announcement" routeName="announcement">
      <div className="main-page-wrapper">
        <div className="w-full px-2 py-2 1sm:flex justify-between gap-4">
            <div className="flex-1 w-full">
              <div className="bg-white rounded-md shadow-lg w-full">
                <div className="px-2 py-3 border-b font-semibold" style={{...borderColor}}>
                      Welcome Videos
                  </div>
                  <div className="p-2">
                      <div className="my-3">
                          <p className="text-xs text-darkGray">This will appear everytime new account logged in to Milestone Page once.</p>
                      </div>
                      <Input
                          inputContainerClass="form-input-container"
                          value={welcomeAnn.url || ""}
                          name="url"
                          outsideLabel="Youtube URL"
                          placeholder="www."
                          outsideLabelClass="text-sm text-lightBlack"
                          noLabelPaddingY="0.8rem"
                          type="text" 
                          errorMessagePosition="bottom"
                          onChange={(e)=>onHandleChange(e)}
                          errorMessage={errors?.type === 'logged' && errors?.url}
                          /> 
                      <Button
                          disabled={isLoading}
                          key="save-btn-category"
                          buttonName="Save Changes"
                          buttonClass="bg-primary py-2 px-6"
                          buttonTextClass="text-white text-sm buttonTextClass flex gap-2" 
                          iconPosition="left"
                          onClick={()=>saveHandle('logged')}
                          loadingIcon={
                              isLoading ? (
                              <LoadingIndicator className="text-white w-4 h-4 ml-2" />
                              ) : null
                          }
                      />
                  </div>
              </div>
            </div>
            <div className="bg-white rounded-md shadow-lg flex-1 w-full">
                <div className="px-2 py-3 border-b font-semibold" style={{...borderColor}}>
                    Add Announcement Video
                </div>
                <div className="p-2">
                  <div className="my-3">
                      <p className="text-xs text-darkGray">This will appear on everyone's account based on date scheduled.</p>
                  </div>
                  <Input
                      inputContainerClass="form-input-container"
                      value={shedAnn.url || ""}
                      name="url"
                      outsideLabel="Youtube URL"
                      placeholder="www."
                      outsideLabelClass="text-sm text-lightBlack"
                      noLabelPaddingY="0.8rem"
                      type="text" 
                      errorMessagePosition="bottom"
                      errorMessage={errors?.type === 'schedule' && errors?.url}
                      onChange={handleInputChange}
                  /> 
                      <div className="flex gap-4">
                        <Radio label="For now" 
                          name="schedule"
                          checked={shedAnn.schedule === "For now" ? true : false}
                          defaultChecked={shedAnn.schedule === "For now" ? true : false}
                          active={shedAnn.schedule === "For now" ? true : false}
                          onChange={() =>
                            handleInputChange({
                              target: { name: "schedule", value: "For now" },
                            })
                          }
                        />
                        <Radio label="For later" 
                          name="schedule"
                          checked={shedAnn.schedule === "For later" ? true : false}
                          defaultChecked={shedAnn.schedule === "For later" ? true : false}
                          active={shedAnn.schedule === "For later" ? true : false}
                          onChange={() =>
                            handleInputChange({
                              target: { name: "schedule", value: "For later" },
                            })
                          }
                        /> 
                      </div>
                      <div className="flex gap-4 mt-2">
                        <Input 
                          inputContainerClass="form-input-container"
                          value={shedAnn.date || ""}
                          name="date"
                          outsideLabel="Date"
                          placeholder="Select Date"
                          outsideLabelClass="text-sm font-semibold text-lightBlack"
                          inputClass="rounded-md border border-gray"
                          noLabelPaddingY="0.8rem"
                          type="date" 
                          errorMessagePosition="bottom"
                          onChange={handleInputChange}
                          errorMessage={errors?.date}
                        /> 
                        <Input 
                          inputContainerClass="form-input-container"
                          inputClass="rounded-md border border-gray"
                          value={shedAnn.time || ""}
                          name="time"
                          outsideLabel="Time"
                          outsideLabelClass="text-sm font-semibold text-lightBlack"
                          noLabelPaddingY="0.8rem"
                          type="time" 
                          errorMessagePosition="bottom"
                          onChange={handleInputChange}
                          errorMessage={errors?.time}
                        /> 
                      </div>
                      <Button
                        disabled={isLoading}
                        key="save-btn-category"
                        buttonName="+ Add Announcement"
                        buttonClass="bg-primary py-2 px-6"
                        buttonTextClass="text-white text-sm buttonTextClass flex gap-2" 
                        iconPosition="left"
                        onClick={()=>saveHandle('schedule')} 
                        loadingIcon={
                            isLoading ? (
                            <LoadingIndicator className="text-white w-4 h-4 ml-2" />
                            ) : null
                        }
                    />
                </div>
            </div>
            <div className="flex-1 w-full">
                <div className="bg-white rounded-md shadow-lg flex-1 w-full mb-4">
                  <div className="px-2 py-3 border-b font-semibold" style={{...borderColor}}>
                      Upcoming Announcements
                  </div>
                  <div className="p-2">
                    {
                      upComing.length > 0 && upComing.map((item)=>{
                        return(
                          <div key={item.id} className="flex justify-between items-center border-b" style={{...borderColor}}>
                              <div>
                                <p className="text-md ">Youtube</p>
                                <p className="text-xs text-darkGray">
                                  {item.url.includes('https://') ? item.url.split('').splice(8).join('') : item.url}
                                </p>
                              </div>
                              <div className="flex gap-4 items-center">
                                <div>
                                  <p className="text-xs text-darkGray">{item?.date}</p>
                                  <p className="text-xs text-darkGray">{item?.time}</p>
                                </div>
                                <div><button onClick={()=>showDelete(item,'upComing')} className="text-red text-lg mr-2"><FiTrash2/></button></div>
                              </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                {
                  previous.length > 0 &&
                  <div className="bg-white rounded-md shadow-lg flex-1 w-full">
                  <div className="px-2 py-3 border-b font-semibold" style={{...borderColor}}>
                      Previous Announcements
                  </div>
                  <div className="p-2">
                  {
                      previous.map((item)=>{
                        return(
                          <div key={item.id} className="flex justify-between items-center border-b" style={{...borderColor}}>
                              <div>
                                <p className="text-md ">Youtube</p>
                                <p className="text-xs text-darkGray">
                                  {item.url.includes('https://') ? item.url.split('').splice(8).join('') : item.url}
                                </p>
                              </div>
                              <div className="flex gap-4 items-center">
                                <div>
                                  <p className="text-xs text-darkGray">{item?.date}</p>
                                  <p className="text-xs text-darkGray">{item?.time}</p>
                                </div>
                                <div><button onClick={()=>showDelete(item, 'previous')} className="text-red text-lg mr-2"><FiTrash2/></button></div>
                              </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>}
            </div>
        </div>
      </div>
    </Layout>
  );
}
