import React,{useState, useEffect} from "react";
import { useLocation,useHistory   } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "layouts/layout"; 
import _ from "lodash";
import { Profile1 } from "constants/images";
import ActionIconBtn from "components/button/action-btn";
import EditPencilIconSVG from "assets/svg/edit-pencil-icon";
import LockIconSVG from "assets/svg/lock-icon";
import UserCheckIconSVG from "assets/svg/user-check-icon";
import { urlSerializer } from "utils/utils"; 
import Table from "components/table/table";
import ListContainer from "components/list/list-container";
import ListHeader from "components/list/list-header";
import { useSelector, useDispatch } from "react-redux";
import ToolTip from "components/tooltip/tooltip";
import GroupEdit from "./edit";
import { useDialogHook } from "utils/customhooks";
import {
    SET_GROUP, 
    GET_GROUP,
    REFRESH_GROUP
  } from "redux/modules/group/types";
 
//end charts

export default function Group() {

    const { http } = global.services;
    const dispatch = useDispatch();
    const history = useHistory ();
    const {
      isRequesting,
      initialize,
      loadMore,
      refresh,
      page,
      limit,
      group = [],
      pages = [],
    } = useSelector((state) => state.group);

    const modal = useDialogHook(GroupEdit);

    // const query = useQuery();
    // const searchValue = query.get("search");
    const [selectedRows, setSelectedRows] = useState([]); 
    const [filterValue, setFilterValue] = useState();

    useEffect(()=>{
        getGroup();
    },[refresh, initialize]);
    
    const handleApproveUser = (data = {}) => {
        history.push(`/group/${data.id}`);
        // approve(data, (result) => {
        //   if (!_.isEmpty(result)) {
        //     if (result === "approve") {
        //       dispatch({
        //         type: REFRESH_USERS,
        //         payload: [],
        //       });
        //     }
        //   }
        // });
      };
    const handleAddEdit = (data = {}) => { 
    modal({data}, (result) => {
        if (!_.isEmpty(result)) {
        console.log("result ", result);
        const {success} = result;
        if (success) {
          dispatch({
            type: REFRESH_GROUP,
            payload: [],
          });
        }
        }
    });
    };
     
      const getGroup = async (selectedPage = null) => {
        if (isRequesting || !loadMore) return;
    
        dispatch({ type: GET_GROUP, payload: [] });
    
        try {
          const url = urlSerializer("group-management", {
            search: filterValue,
            page: selectedPage ?? page,
            limit: limit,
          });
          const { data } = await http.get(url);
          const _loadMore = data.data.length > 0 ? true : false;
          // const _page = data.data.length > 0 ? page + 1 : page;
          const _page = data.current_page;
          const _lastPage = data.last_page;
          console.log(data)
    
          dispatch({
            type: SET_GROUP,
            payload: {
              data: data.data,
              loadMore: _loadMore,
              page: _page,
              lastPage: _lastPage,
            },
          });
        } catch (err) {
          console.log("error@groupGroup", err);
        }
      };
    const columns = [
        {
          title: "Team Name",
          dataIndex: "team_name",
          nowrap: true,
          key: "team_name",
        }, 
        {
          title: "Email",
          dataIndex: "emailUser",
          nowrap: true,
          key: "emailUser",
          render: (profile) => { 
            return profile?.user.email;
          },
        },
        {
          title: "Profile Type",
          dataIndex: "userType",
          nowrap: true,
          key: "userType",
          render: (profile) => { 
            return  <span className="capitalize">{profile?.profile_type?.type }</span>;

          },
        },
        {
            title: "No.of Members",
            dataIndex: "countMembers",
            nowrap: true,
            key: "countMembers",
            render: (profile) => {  
                if(profile?.profile_type?.members){
                    return JSON.parse(profile?.profile_type?.members).length;
                } 
                return 0;
            },
          },
        {
          title: "Status",
          dataIndex: "status",
          nowrap: true,
          key: "status",
          render: (profile) => {
            if(profile?.team_status === 'pending' || profile?.team_status === null) {
              return <span className="capitalize">Pending</span>;
            }else{ 
              return  <span className="capitalize font-bold" 
              style={{color: profile?.team_status  === 'approved' ? 'green' : 'red'}} 
              >{profile?.team_status === 'approved' ? 'Verified' : profile?.team_status}</span>;
            }
           

          },
        },
    
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          hideSorting: true,
          nowrap: true,
          render: (profile) => (
            <div className="flex">
            <ToolTip
              value={`Edit`}
              backgroundColor="#0c6ed1"
              color="white"
              width='97px'
              >
               <ActionIconBtn
                icon={<EditPencilIconSVG className="m-auto" />}
                onClick={() => handleAddEdit(profile)}
              /> 
            </ToolTip>
              
              {profile?.team_status === 'pending' || profile?.team_status === null && (
                <ToolTip
                value={`For Approval`}
                backgroundColor="#0c6ed1"
                color="white"
                width='105px'>
                <ActionIconBtn
                  icon={<UserCheckIconSVG className="m-auto" />}
                  onClick={() => handleApproveUser(profile)}
                />
                </ToolTip>
              )} 
            </div>
          ),
        },
      ];

   
  return (
    <Layout title="Group" routeName="group">  
        <div className="main-page-wrapper">
        <ListContainer
          header={
            <ListHeader
              title="List"
              countValue={_.size(group)}
              filterValue={filterValue}
              setFilterValue={setFilterValue}  
            />
          }
        >
          <Table
            columns={columns}
            dataSource={group}
            pagination={true}
            selectable={true}
            selected={selectedRows}
            onSelected={(selectedRows) => {
              setSelectedRows(selectedRows);
            }}
            perPage={10}
            currentPage={page}
            onHandleChangePage={getGroup}
            pages={pages}
          />
        </ListContainer>
      </div>
    </Layout>
  );
}; 

const useQuery = () => {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };