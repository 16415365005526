import React from "react";
import Modal from "components/global/modal"
import ModalHeader from "components/global/modal-header";
import Button from "components/button/button"; 
import toast from "react-hot-toast";
import { useState } from "react";
import LoadingIndicator from "assets/svg/loading-indicator"; 
import Input from "components/input/input";
const GroupEdit = ({data,hide=()=>{}, ...props}) =>{ 
    const {team_name,id} = data;
    const { http } = global.services;
    const [isLoading,setIsLoading] = useState(false);
    const [teamName, setTeamName] = useState(team_name)
    const handleUpdate = async()=>{
        if(teamName === '') return;
        try{
          const payload = {
            id:id,
            team_name:teamName, 
          }
          setIsLoading(true) 
          const {data } = await http.put(`team-user/${id}`, payload);
          toast.success(data.message);
          hide({success:true, data:data});
        }catch(err){
          toast.error(`It appears that something went wrong`); 
        }finally{
          hide({success:false});
        }

    }

    const handleInputChange = (e) => {
      const {value } = e.target;
      setTeamName(value);
    };

    const Footer = () => {
        return (
          <div className="relative flex flex-row items-center justify-end space-x-2">
            <Button
              key="cancel-btn-category"
              buttonName="Cancel"
              buttonClass="bg-thBg py-3 px-9"
              buttonTextClass="text-black text-xs font-bold"
              onClick={() => hide()} 
            />
            <Button 
              disabled={isLoading}
              key="save-btn"
              buttonName="Save Changes"
              buttonClass="bg-primary py-3 px-8 "
              buttonTextClass="text-white text-xs font-bold capitalize flex"
              onClick={handleUpdate}
              loadingIcon={
                isLoading ? (
                <LoadingIndicator className="text-white w-4 h-4 ml-2" />
                ) : null
            }
            />
          </div>
        );
      };

    return (
        <Modal
        footer={<Footer />}
        width="w-16/20 md:w-13/20 2md:w-9/20 3md:w-6/20 1xl:w-5/20"
        contentClassName="rounded-lg overflow-auto custom-scroll"
        wrapperClassName="overflow-visible"
        footerClass="border-t border-mutedGray"
        >
            <ModalHeader
            title="Edit Team"
            hide={hide}
            />

            <div className="px-4 py-4"> 
                <Input
                    inputContainerClass="form-input-container"
                    value={teamName || ""}
                    name="english_version"
                    outsideLabel="Team Name "
                    placeholder="Team Name"
                    outsideLabelClass="text-sm font-semibold text-lightBlack"
                    noLabelPaddingY="0.8rem"
                    type="text"
                    onChange={handleInputChange} 
                    errorMessagePosition="bottom"
                    />
            </div>
        </Modal>
    )
}

export default GroupEdit;